import {
  FileType,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import {
  BrandInterface,
  ServiceCenterDealerDivisionInterface,
  ServiceCenterDealerInterface,
  ServiceCenterFullInferface,
  ServiceCenterInferface,
} from "@/typings/model";

export interface CountData {
  count: number;
}

export interface ServiceCenterCreateOrUpdatePayload {
  apiDealer: Nullable<number>;
  apiDealerDivision: Nullable<number>;
  name: string;
  address: string;
  image: Nullable<FileType["id"]>;
  city: number | null;
  latitude: number;
  longitude: number;
  phone: string;
  brands: number[];
  worktime: Array<string | null>;
  isBodyRepairEnabled: boolean;
}

export function getServiceCentersDealers(): Promise<
  ResponseBody<Array<ServiceCenterDealerInterface>>
> {
  return request.get({ path: "/admin/service-centers/dealers" });
}

export function getServiceCentersDealerDivisions(
  dealerId: number
): Promise<ResponseBody<Array<ServiceCenterDealerDivisionInterface>>> {
  return request.get({
    path: "/admin/service-centers/dealers/" + dealerId + "/divisions",
  });
}

export function getServiceCentersCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/service-centers/count" });
}

export function getServiceCentersList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ServiceCenterInferface>>> {
  return request.get({ path: "/admin/service-centers", params });
}

export function getServiceCenter(
  id: string | number
): Promise<ResponseBody<ServiceCenterFullInferface>> {
  return request.get({ path: `/admin/service-centers/${id}` });
}

export function createServiceCenter(
  payload: ServiceCenterCreateOrUpdatePayload
): Promise<ResponseBody<ServiceCenterFullInferface>> {
  return request.post({
    path: `/admin/service-centers`,
    body: payload,
  });
}

export function updateServiceCenter(
  id: number | string,
  payload: ServiceCenterCreateOrUpdatePayload
): Promise<ResponseBody<ServiceCenterFullInferface>> {
  return request.put({
    path: `/admin/service-centers/${id}`,
    body: payload,
  });
}

export function deleteServiceCenter(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/service-centers/${id}` });
}

export function moveServiceCenter(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/service-centers/${id}/move/${direction}`,
  });
}
