
import {defineComponent} from "vue";
import {Page} from "@tager/admin-layout";

import {useDataTable, DataTable, BaseButton, DeleteIcon, EditIcon, SouthIcon, NorthIcon, CountButton} from '@tager/admin-ui';
import {CityInterface} from '@/typings/model';
import {COLUMN_DEFS} from "./CitiesList.helpers";

import {getCitiesCreateUrl, getCitiesUpdateUrl, getServiceCentersListUrl} from "@/utils/paths";
import {useResourceDelete, useResourceMove} from "@tager/admin-services";
import {deleteCity, getCities, moveCity} from "@/services/cities";

export default defineComponent({
  name: 'CitiesList',
  components: {
    Page,
    DataTable,
    SouthIcon, NorthIcon, DeleteIcon, EditIcon,
    BaseButton, CountButton,
  },
  setup() {
    const {
      fetchEntityList: fetchCitiesList,
      isLoading: isCitiesListDataLoading,
      rowData: citiesListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CityInterface>({
      fetchEntityList: (params) =>
          getCities({
            query: params.searchQuery,
            pageNumber: params.pageNumber,
            pageSize: params.pageSize,
          }),
      initialValue: [],
      resourceName: 'Список брендов',
    });

    const {isMoving, handleResourceMove} = useResourceMove({
      moveResource: moveCity,
      resourceName: "Город",
      onSuccess: fetchCitiesList,
    });

    const {isDeleting, handleResourceDelete} = useResourceDelete({
      deleteResource: deleteCity,
      resourceName: "Город",
      onSuccess: fetchCitiesList,
    });

    function isBusy(id: number): boolean {
      return (
          isDeleting(id) ||
          isMoving(id) ||
          isCitiesListDataLoading.value
      );
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: citiesListData,
      isRowDataLoading: isCitiesListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      getCitiesCreateUrl,
      getCitiesUpdateUrl,
      getServiceCentersListUrl,
      handleResourceDelete,
      handleResourceMove,
    };
  },
});
