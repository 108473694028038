export const Scope = {
  All: "*",
  ServicesView: "services.view",
  ServicePagesView: "service-pages.view",
  ServiceCentersView: "service-centers.view",
  BrandsView: "brands.view",
  BrandsCreate: "brands.create",
  BrandsEdit: "brands.edit",
  BrandsDelete: "brands.delete",
  CitiesView: "cities.view",
  ImportServices: "import.services",
  ExportServices: "export.services",
  AdministratorsView: "administrators.view",
  SettingsView: "settings.view",
} as const;
