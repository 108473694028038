import { type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,
} from "@tager/admin-settings";
import { EXPORT_LIST_ROUTE, EXPORT_FORM_ROUTE } from "@tager/admin-export";
import { IMPORT_LIST_ROUTE, IMPORT_FORM_ROUTE } from "@tager/admin-import";

import Home from "@/views/Home.vue";
import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import BrandsList from "@/views/BrandsList";
import BrandsForm from "@/views/BrandsForm";
import CitiesList from "@/views/CitiesList";
import CitiesForm from "@/views/CitiesForm";
import ServiceCentersList from "@/views/ServiceCentersList";
import ServiceCentersForm from "@/views/ServiceCentersForm";
import ServicesList from "@/views/ServicesList";
import ServicesForm from "@/views/ServicesForm";
import ServicePagesList from "@/views/ServicePagesList";
import ServicePagesForm from "@/views/ServicePagesForm";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: (route, i18n) => [{ url: "/", text: i18n.t("admin:home") }],
  },
};

export const BRANDS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.BRANDS_LIST,
  component: BrandsList,
  name: "Бренды",
  meta: {
    getBreadcrumbs: (route, t) => [getLinks(t).HOME, getLinks(t).BRANDS_LIST],
  },
};

export const BRANDS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.BRANDS_FORM,
  component: BrandsForm,
  name: "Форма бренда",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).BRANDS_LIST,
      {
        url: route.path,
        text: "Форма бренда",
      },
    ],
  },
};

export const CITIES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CITIES_LIST,
  component: CitiesList,
  name: "Города",
  meta: {
    getBreadcrumbs: (route, t) => [getLinks(t).HOME, getLinks(t).CITIES_LIST],
  },
};

export const CITIES_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CITIES_FORM,
  component: CitiesForm,
  name: "Форма города",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).CITIES_LIST,
      {
        url: route.path,
        text: "Форма города",
      },
    ],
  },
};

export const SERVICE_CENTERS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_CENTERS_LIST,
  component: ServiceCentersList,
  name: "Сервисные центры",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).SERVICE_CENTERS_LIST,
    ],
  },
};

export const SERVICE_CENTERS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_CENTERS_FORM,
  component: ServiceCentersForm,
  name: "Форма сервисного центра",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).SERVICE_CENTERS_LIST,
      {
        url: route.path,
        text: "Форма сервисного центра",
      },
    ],
  },
};

export const SERVICE_PAGES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_PAGES_LIST,
  component: ServicePagesList,
  name: "Сервисные страницы",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).SERVICE_PAGES_LIST,
    ],
  },
};

export const SERVICE_PAGES_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_PAGES_FORM,
  component: ServicePagesForm,
  name: "Форма сервисной страницы",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).SERVICE_PAGES_LIST,
      {
        url: route.path,
        text: "Форма сервисной страницы",
      },
    ],
  },
};

export const SERVICES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICES_LIST,
  component: ServicesList,
  name: "Сервисы",
  meta: {
    getBreadcrumbs: (route, t) => [getLinks(t).HOME, getLinks(t).SERVICES_LIST],
  },
};

export const SERVICES_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICES_FORM,
  component: ServicesForm,
  name: "Форма сервиса",
  meta: {
    getBreadcrumbs: (route, t) => [
      getLinks(t).HOME,
      getLinks(t).SERVICES_LIST,
      {
        url: route.path,
        text: "Форма сервиса",
      },
    ],
  },
};

export const router = createRouter({
  routes: [
    HOME_ROUTE,

    ADMIN_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,

    BRANDS_LIST_ROUTE,
    BRANDS_FORM_ROUTE,
    CITIES_LIST_ROUTE,
    CITIES_FORM_ROUTE,
    SERVICE_CENTERS_LIST_ROUTE,
    SERVICE_CENTERS_FORM_ROUTE,
    SERVICES_LIST_ROUTE,
    SERVICES_FORM_ROUTE,
    SERVICE_PAGES_LIST_ROUTE,
    SERVICE_PAGES_FORM_ROUTE,

    EXPORT_LIST_ROUTE,
    EXPORT_FORM_ROUTE,
    IMPORT_LIST_ROUTE,
    IMPORT_FORM_ROUTE,

    SETTINGS_ITEM_FORM_ROUTE,
    SETTINGS_ITEM_LIST_ROUTE,
  ],
});
