
import { computed, defineComponent } from "vue";

import { isNotNullish, useI18n } from "@tager/admin-services";
import { HomeIcon, ViewListIcon } from "@tager/admin-ui";
import { MenuItemType, PageLayout } from "@tager/admin-layout";

import { getLinks } from "@/constants/links";
import { Scope } from "@/constants/scopes";

export default defineComponent({
  name: "BaseApp",
  components: { PageLayout },
  setup() {
    const i18n = useI18n();

    const links = computed(() => getLinks(i18n));

    const sidebarMenuList = computed<Array<MenuItemType>>(() => {
      return [
        { id: "home", icon: HomeIcon, ...links.value.HOME },
        {
          id: "services",
          icon: ViewListIcon,
          scopes: [Scope.ServicesView],
          ...links.value.SERVICES_LIST,
        },
        {
          id: "services_pages",
          icon: ViewListIcon,
          scopes: [Scope.ServicePagesView],
          ...links.value.SERVICE_PAGES_LIST,
        },
        {
          id: "service_centers",
          icon: ViewListIcon,
          scopes: [Scope.ServiceCentersView],
          ...links.value.SERVICE_CENTERS_LIST,
        },
        {
          id: "brands",
          icon: ViewListIcon,
          ...links.value.BRANDS_LIST,
          scopes: [Scope.BrandsView],
        },
        {
          id: "export",
          icon: ViewListIcon,
          ...links.value.EXPORT,
          scopes: [Scope.ExportServices],
        },
        {
          id: "import",
          icon: ViewListIcon,
          ...links.value.IMPORT,
          scopes: [Scope.ImportServices],
        },
        {
          id: "settings",
          icon: ViewListIcon,
          ...links.value.CITIES_LIST,
          scopes: Scope.CitiesView,
        },
        {
          id: "cities",
          icon: ViewListIcon,
          ...links.value.SETTINGS_LIST,
          scopes: Scope.SettingsView,
        },
        {
          id: "admins",
          text: i18n.t("admin:administrators"),
          icon: ViewListIcon,
          children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          scopes: Scope.AdministratorsView,
        },
      ].filter(isNotNullish);
    });

    return {
      sidebarMenuList,
    };
  },
});
