export const ROUTE_PATHS = {
  BRANDS_LIST: "/brands",
  BRANDS_FORM: "/brands/:id",
  CITIES_LIST: "/cities",
  CITIES_FORM: "/cities/:id",
  SERVICE_CENTERS_LIST: "/service-centers",
  SERVICE_CENTERS_FORM: "/service-centers/:id",
  SERVICES_LIST: "/services",
  SERVICES_FORM: "/services/:id",
  SERVICE_PAGES_LIST: "/service-pages",
  SERVICE_PAGES_FORM: "/service-pages/:id",
};
