
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldFileInput,
  FormFieldSelect,
  FormFieldMultiSelect,
  FormFieldCheckbox,
  OptionType,
} from "@tager/admin-ui";

import {
  updateServiceCenter,
  createServiceCenter,
  getServiceCenter,
} from "@/services/service-centers";
import {
  getServiceCentersListUrl,
  getServiceCentersUpdateUrl,
} from "@/utils/paths";
import {
  getServiceCentersDealers,
  getServiceCentersDealerDivisions,
} from "@/services/service-centers";
import { getCities } from "@/services/cities";
import { getBrandsList } from "@/services/brands";

import {
  FormValues,
  getServiceCenterFormValues,
  convertPageFormValuesToCreationOrUpdatePayload,
} from "./ServiceCentersForm.helpers";

export default defineComponent({
  name: "ServiceCentersForm",
  components: {
    Page,
    FormField,
    FormFieldFileInput,
    FormFieldSelect,
    FormFieldMultiSelect,
    FormFooter,
    FormFieldCheckbox,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [
      fetchServiceCenter,
      { data: serviceCenter, loading: isServiceCenterLoading },
    ] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getServiceCenter(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "ServiceCenter",
    });

    const [
      fetchCitiesList,
      { data: citiesList, loading: isCitiesListLoading },
    ] = useResource({
      fetchResource: () => getCities(),
      initialValue: [],
      resourceName: "Cities list",
    });

    const [
      fetchBrandsList,
      { data: brandsList, loading: isBrandsListLoading },
    ] = useResource({
      fetchResource: () => getBrandsList(),
      initialValue: [],
      resourceName: "Cities list",
    });

    const [
      fetchDealersList,
      { data: dealersList, loading: isDealersListLoading },
    ] = useResource({
      fetchResource: () => getServiceCentersDealers(),
      initialValue: [],
      resourceName: "Dealers list",
    });

    const [
      fetchDealerDivisionsList,
      { data: dealerDivisionsList, loading: isDealerDivisionsListLoading },
    ] = useResource({
      fetchResource: (dealerId: number) =>
        getServiceCentersDealerDivisions(dealerId),
      initialValue: [],
      resourceName: "Dealer Divisions list",
    });

    onMounted(() => {
      fetchServiceCenter();
      fetchCitiesList();
      fetchBrandsList();
      fetchDealersList();
    });

    watch(id, fetchServiceCenter);

    const citiesOptions = computed<Array<OptionType<number>>>(() => {
      return [
        ...citiesList.value.map((city) => ({
          value: city.id,
          label: city.name,
        })),
      ];
    });

    const brandsOptions = computed<Array<OptionType<number>>>(() => {
      return brandsList.value.map((brand) => ({
        value: brand.id,
        label: brand.name,
      }));
    });

    const dealersOptions = computed<Array<OptionType<number>>>(() => {
      return [
        { value: 0, label: "Нет связи с API" },
        ...dealersList.value.map((dealer) => ({
          value: dealer.id,
          label: dealer.name,
        })),
      ];
    });

    const dealersDivisionsOptions = computed<Array<OptionType<number>>>(() => {
      return [
        { value: 0, label: "Нет связи с API" },
        ...dealerDivisionsList.value.map((dealer) => ({
          value: dealer.id,
          label: dealer.name,
        })),
      ];
    });

    watch([citiesOptions, brandsOptions, dealersOptions], () => {
      updateFormValues();
    });

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getServiceCenterFormValues(
        serviceCenter.value,
        dealersOptions.value,
        citiesOptions.value,
        brandsOptions.value
      )
    );

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getServiceCenterFormValues(
        serviceCenter.value,
        dealersOptions.value,
        citiesOptions.value,
        brandsOptions.value
      );
    }

    const apiDealerId = computed<number | undefined>(
      () => values?.value?.apiDealer?.value
    );

    watch([apiDealerId], () => {
      if (apiDealerId.value) {
        fetchDealerDivisionsList(apiDealerId.value);
      }
    });

    const showWorkTime = computed<boolean>(
      () => !values.value?.apiDealerDivision?.value
    );

    onMounted(() => {
      updateFormValues();
    });

    watch([serviceCenter], () => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(values.value);

      const requestPromise = isCreation.value
        ? createServiceCenter(createOrUpdatePayload)
        : updateServiceCenter(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getServiceCentersUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getServiceCentersListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getServiceCenterFormValues(
              null,
              dealersOptions.value,
              citiesOptions.value,
              brandsOptions.value
            );
          }

          toast.show({
            variant: "success",
            title: "Сервисные центры",
            body: isCreation.value
              ? "Сервисный центр успешно создан"
              : "Сервисный центр успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Сервисные центры",
            body: isCreation.value
              ? "Ошибка добавления сервисного центра"
              : "Ошибка изменения сервисного центра",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isLoading = computed<boolean>(
      () =>
        isServiceCenterLoading.value ||
        isBrandsListLoading.value ||
        isDealersListLoading.value ||
        isDealerDivisionsListLoading.value ||
        isCitiesListLoading.value
    );

    return {
      isLoading,
      values,
      errors,
      serviceCenter,
      isCreation,
      isSubmitting,
      submitForm,
      backButtonUrl: getServiceCentersListUrl(),
      citiesOptions,
      brandsOptions,
      dealersOptions,
      dealersDivisionsOptions,
      showWorkTime,
    };
  },
  computed: {
    console: () => console,
  },
});
